import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import {AiOutlineShopping} from "react-icons/ai";

const fontSizeStyle = {
  fontSize: "2.6em", // Standard-Schriftgröße
};

const smallerFontSizeStyle = {
  fontSize: "1.5em", // Kleiner Schriftgröße für Smartphones
};



function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
          <h1 style={window.innerWidth <= 768 ? smallerFontSizeStyle : fontSizeStyle}>
              MEINE <span className="purple"> PROGRAMMIER</span>REISE :
            </h1>
            <p className="home-about-body">
            Die Liebe zur Programmierung hat mich erfasst, und 
            auf meiner Reise habe ich nicht nur gelernt, sondern auch meine Leidenschaft entdeckt... 🚀
              <br />
              <br />Ich beherrsche Klassiker wie
              <i>
                <b className="purple"> C++, Javascript und SQL. </b>
              </i>
              <br />
              <br />
              Meine Interessen liegen in der Entwicklung neuer &nbsp;
              <i>
                <b className="purple">Web-Technologien und -Produkte </b></i> sowie
                die Gestaltung von Projekten wie{" "}
              <i>  <b className="purple">
                  Künstliche Intelligenz.
                </b>
              </i>
              <br />
              <br />
              Immer, wenn es möglich ist, setze ich meine Begeisterung in die Praxis um 
              und entwickle Produkte mit <b className="purple">Node.js</b> und
              <i>
                <b className="purple">
                  {" "}
                  modernen Javascript Libraries und Frameworks
                </b>
              </i>
              &nbsp; wie
              <i>
                <b className="purple"> React.js and Next.js</b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FOLGE MIR AUF</h1>
            <p>
             oder <a href="mailto:o.icli@ocean-site.de"><span className="purple">kontaktiere </span></a>mich
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/oceanbang"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/ocean_site_/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://ocean-wear.store/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiOutlineShopping />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
