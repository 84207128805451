import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import Lottie from "react-lottie";
import developanimation from "../../Lottie/developanimation.json";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Hi!{" "}
                <span className="wave" role="img" aria-labelledby="wave">
                👋🏽
                </span>
              </h1>

              <h1 className="heading-name">
                Ich bin
                <strong className="main-name"> OGUZHAN ICLI</strong> 
              </h1>

              <div style={{ padding: 50, textAlign: "left" }}>
                <Type />
              </div>
            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>
              {/* Ersetze das statische Bild mit der Lottie-Animation */}
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData : developanimation, // Animation Daten aus der Datei
                  rendererSettings: {
                    preserveAspectRatio: "xMidYMid slice",
                  },
                }}
                height={450}
                width="100%"
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Home2 />
    </section>
  );
}

export default Home;
