import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import laufmitmel from "../../Assets/Projects/laufmitmel.png";
import oceanwear from "../../Assets/Projects/oceanwear.png";
import wsiw from "../../Assets/Projects/wsiw.png";
import bringit from "../../Assets/Projects/bringit.png";
import wetter from "../../Assets/Projects/wetter.png";
import weyher from "../../Assets/Projects/weyher.png";
import dragon from "../../Assets/Projects/dragon.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Mein <strong className="purple">Portfolio </strong>
        </h1>
        <p style={{ color: "white" }}>
          Hier findest du meine Projekte.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={oceanwear}
              isBlog={false}
              title="ocean-wear"
              description='Mit "ocean-wear" konnte ich einen weiteren Schritt in die Selbstständigkeit wagen und mein Herzensprojekt umsetzen. Diese Marke steht für nachhaltige Mode, welches 10% an gemeinnützige Orgranisationen spendet.'
              ghLink="https://github.com/oceanbang"
              demoLink="https://ocean-wear.store/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={laufmitmel}
              isBlog={false}
              title="Lauf mit Mel"
              description='"Lauf mit Mel" ist ein Laufverein und einer meiner ersten Kund:innen. Der Verein bietet Lauftraining und -veranstaltungen für Menschen jeden Alters und jeder Fitnessstufe an. Ziel des Vereins ist es, Menschen zu motivieren, ihre körperliche Fitness zu verbessern und ein gesünderes Leben zu führen.'
              ghLink="https://github.com/oceanbang"
              demoLink="https://laufmitmel.de/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={wsiw}
              isBlog={false}
              title="What should I watch?"
              description="Diese App soll dir dabei helfen, Filme und Serien basierend auf deinen Emotionen auszuwählen. Wenn du beispielsweise gestresst bist und einen Film sehen möchtest, der dich beruhigt, kann die App dir dabei helfen, den perfekten Film zu finden. “What should I watch?” ist die perfekte App für alle, die auf der Suche nach personalisierten Empfehlungen sind und sich nicht sicher sind, was sie als nächstes schauen sollen. - TBA"
              ghLink="https://github.com/oceanbang"
              //demoLink="https://editor.soumya-jit.tech/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bringit}
              isBlog={false}
              title="Bringit"
              description="Bringit ist eine Online-Plattform aus meiner Heimatstadt, die es dir ermöglicht, Lebensmittel und andere Produkte online zu bestellen und direkt an deine Haustür liefern zu lassen. Das Unternehmen bietet eine breite Palette an Produkten an, darunter frisches Obst und Gemüse, Fleisch, Milchprodukte, Getränke und vieles mehr. Demnächst bietet Bringit auch eine mobile App an, mit der du von unterwegs aus einkaufen und Bestellungen aufgeben kannst."
              ghLink="https://github.com/oceanbang"
              demoLink="https://bringit-now.de/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={wetter}
              isBlog={false}
              title="Wettr"
              description="Wettr ist eine Wetter-App, die dir nicht nur die aktuelle Wetterlage und die Vorhersage für die nächsten Tage anzeigt, sondern auch viele nützliche Funktionen bietet, die dir das Leben leichter machen. - TBA"
              ghLink="https://github.com/oceanbang"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={weyher}
              isBlog={false}
              title="Gästehaus-Siener"
              description="Ob wandern, radfahren, klettern oder einfach nur die Natur und die Weinkultur erleben, beim Gästehaus-Siener findest du den idealen Ausgangspunkt für deine Aktivitäten. Ich war schon öfter vor Ort und kann Weyher wärmstens empfehlen. Ein kleiner Secret Spot."
              ghLink="https://github.com/oceanbang"
              demoLink="https://www.gaestehaus-siener.de/"  
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={dragon}
              isBlog={false}
              title="Beat the dragon"
              description='In "Beat the dragon" schlüpfst du in die Rolle eines mutigen Helden, der sich auf eine gefährliche Reise begibt, um einen furchterregenden Drachen zu besiegen. "Beat the dragon" ist ein textbasiertes Rollenspiel, das dich in eine mittelalterliche Fantasy-Welt entführt. Du kannst das Spiel in deinem Browser spielen, ohne etwas herunterladen zu müssen. - TBA'
              ghLink="https://github.com/oceanbang"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
