import React, { useState } from "react";
import { Card, Button, Modal } from "react-bootstrap";

const ArticleList = ({ articles }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);

  const handleArticleClick = (article) => {
    setSelectedArticle(article);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <h2 className="purple">Not again another Blog</h2>
      <br/>
      {articles.map((article, index) => (
        <div key={index} className="mb-3">
          <Card>
            <Card.Body>
              <Card.Title>{article.title}</Card.Title>
              <Card.Text className="cardcontent">{article.content}</Card.Text>
              <Card.Text className="articledate">{article.date}</Card.Text>
              <Button variant="primary" onClick={() => handleArticleClick(article)}>
                Lesen
              </Button>
            </Card.Body>
          </Card>
        </div>
      ))}

      {/* Modal für ausgewählten Artikel */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedArticle?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{selectedArticle?.fullContent}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ArticleList;

