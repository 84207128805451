import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";
import {MdOutlineTravelExplore} from "react-icons/md";
import {IoGameController} from "react-icons/io5";
import {GiThreeFriends} from "react-icons/gi";
import Lottie from "react-lottie";
import cat from "../../Lottie/cat.json";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi, ich bin <span className="purple">Oguzhan Icli </span>
            aus <span className="purple"> Deutschland</span>.
            <br />
            Ich bin leidenschaftlicher Softwareentwickler und liebe es zu coden. 
            <br />
            So habe ich den Schritt in die Selbstständigkeit gewagt und ein Kleinunternehmen gegründet.
            <br />
            <br />
            Abseits vom coden, sind meine weiteren Interessen:
          </p>
          <ul>
            <li className="about-activity">
              <IoGameController /> Games zocken
            </li>
            <li className="about-activity">
              <MdOutlineTravelExplore /> Reisen
            </li>
            <li className="about-activity">
              <GiThreeFriends /> mit Freunden treffen
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
          "Software is the glue that holds the world together."{" "}
          </p>
          <footer className="blockquote-footer">Bill Gates (1995, "The Road Ahead")</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
