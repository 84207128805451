import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import Particle from "../Particle";
import ArticleList from "./ArticleList"; // Komponente für die Anzeige der Artikel
import Passwort from "./Artikel/Passwort/Passwort";
import AI from "./Artikel/KI/AI";

function ResumeNew() {
  const [width, setWidth] = useState(1200);
  const [showModal, setShowModal] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  // Dummy-Artikeldaten (ersetze dies durch deine echten Daten)
  const articles = [
    {
      title: "Die Bedeutung von Passwortsicherheit",
      content: "Passwort vergessen?",
      fullContent: <Passwort/>,
      date: "01. Februar 2024" 
    },
    {
      title: "Die Zukunft der künstlichen Intelligenz",
      content: "Befreiung oder Versklavung der Menschheit?",
      fullContent: <AI/>,
      date: "04. Januar 2024"
    },
    // Füge weitere Artikel hinzu
  ];

  const handleArticleClick = (article) => {
    setSelectedArticle(article);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <Container fluid className="blog-section">
        <Particle />

        {/* Blog-Artikel Liste anzeigen */}
        <Row className="mb-4">
          <Col>
            <ArticleList articles={articles} onArticleClick={handleArticleClick} />
          </Col>
        </Row>

        {/* Modal für ausgewählten Artikel */}
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>{selectedArticle?.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{selectedArticle?.fullContent}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Schließen
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
}

export default ResumeNew;
